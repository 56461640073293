import React from "react";
import { List, Avatar, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Logo from "../../../image/icon/Header-logo.svg";
import AccountIcon from "../../../image/icon/account_icon.svg";
import styles from "./ProjectManager.module.css";
import { IProjectItem } from "../../../common/define-types";
import { useDispatchRoot } from "../../../store/store";
import { setProjectSelected } from "../../../store/slice/projectSlice";
import { useHistory } from "react-router-dom";

interface IProps {
  item: IProjectItem;
}
export const ProjectItem = ({ item }: IProps) => {
  const dispatch = useDispatchRoot();
  const history = useHistory();
  const handleProjectClick = () => {
    dispatch(setProjectSelected(item));
    history.push("/project/dashboard");
  };
  return (
    <List.Item
      onClick={handleProjectClick}
      className={`${styles.projectItem} projectItem`}
      extra={
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Button
            style={{ backgroundColor: "#F3F3F7" }}
            shape="circle"
            icon={<EditOutlined />}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      }
    >
      <List.Item.Meta
        style={{ marginBlockEnd: 0 }}
        avatar={<Avatar src={item.imgUrl ?? Logo} size={60} />}
        title={item.name}
        description={
          <span style={{ display: "flex", gap: 5 }}>
            <img src={AccountIcon} alt="account icon" />
            {item.ownerEmail}
          </span>
        }
      />
    </List.Item>
  );
};
