import { Dropdown, LayoutProps, MenuProps } from "antd";
import React, { FC } from "react";
import UploadFileIcon from "../../image/icon/upload-icon.svg";
import NewFolderIcon from "../../image/icon/new-folder-icon.svg";
import NewTextIcon from "../../image/icon/new-text-icon.svg";
import DocumentIcon from "../../image/icon/doc.svg";
import SpreadsheetIcon from "../../image/icon/xls.svg";
import PresentationIcon from "../../image/icon/pdf.svg";
import { ContentMenuItem } from "../../pages/screen/projects/projectDetail/projectFile/ContentMenuItem";
export const UploadMenu: FC<LayoutProps> = ({ children }) => {
  const uploadItems = [
    {
      label: "Upload File",
      imgSrc: UploadFileIcon,
    },
    {
      label: "New Folder",
      imgSrc: NewFolderIcon,
    },
    {
      label: "New Text Document",
      imgSrc: NewTextIcon,
    },
    {
      label: "Document",
      imgSrc: DocumentIcon,
    },
    {
      label: "Spreadsheet",
      imgSrc: SpreadsheetIcon,
    },
    {
      label: "Presentation",
      imgSrc: PresentationIcon,
    },
  ];
  const items: MenuProps["items"] = uploadItems.map((item, index) => ({
    label: <ContentMenuItem label={item.label} imgSrc={item.imgSrc} />,
    key: index,
  }));
  return (
    <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
      {children}
    </Dropdown>
  );
};
