const SYSTEM_CONSTANTS = {
    IDENTITY: {
        HOST: 'https://license.hicas.vn/identity',
        LOGIN: 'connect/token',
        FORGOT: 'users',
        PROFILE: 'api/users/1?populate=role',
        ROLE: 'api/users-permissions/roles',
        CAPTCHA: 'clients/captcha'
    },
};

export default SYSTEM_CONSTANTS;
export const FILE_VIEW = {
    LIST_VIEW : 'list_view',
    GRID_VIEW : 'grid_view',
    TREE_VIEW : 'tree_view',
}
export const MEMBER_VIEW = {
    MEMBERS : 'Members',
    ROLES : 'Roles',
}
export const MEMBER_ROLE = {
    ADMIN : 'Admin',
    MANAGER : 'Manager',
    LEADER : 'Leader',
    TEAM : 'Team',
    MEMBER : 'Member',
}
export const FILE_EXTENSION = {
    CSS : 'CSS',
    DAT : 'DAT',
    DLL : 'DLL',
    DMG : 'DMG',
    DOC : 'DOC',
    EPS : 'EPS',
    FLA : 'FLA',
    FLV : 'FLV',
    GIF : 'GIF',
    HTML : 'HTML',
    INDD : 'INDD',
    ISO : 'ISO',
    JPG : 'JPG',
    JS : 'JS',
    JSX : 'JSX',
    MIDI : 'MIDI',
    MOV : 'MOV',
    MP3 : 'MP3',
    MPG : 'MPG',
    PDF : 'PDF',
    PHP : 'PHP',
    PNG : 'PNG',
    PPT : 'PPT',
    PS : 'PS',
    PSD : 'PSD',
    RAW : 'RAW',
    SQL : 'SQL',
    SVG : 'SVG',
    TIF : 'TIF',
    TXT : 'TXT',
    WMV : 'WMV',
    XLS : 'XLS',
    XML : 'XML',
    ZIP : 'ZIP',
    THREEDS : '3DS',
    AAC : 'AAC',
    AI : 'AI',
    AVI : 'AVI',
    BMP : 'BMP',
    CAD : 'CAD',
    CDR : 'CDR',
}
