import React, { useState } from "react";
import { IFile } from "../../../../../common/define-types";
import { GridViewFileItem } from "./GridViewFileItem";
import styles from "./ProjectFile.module.css";
import { Checkbox, Divider, Empty, Space } from "antd";
import { useDispatchRoot, useSelectorRoot } from "../../../../../store/store";
import { setCurrentDisplayFiles } from "../../../../../store/slice/projectSlice";

interface IProps {
  files: IFile[];
}
export const GridViewFiles = ({ files }: IProps) => {
  const selectCheckedFiles = useSelectorRoot(
    (state) => state.project.currentDisplayFiles
  ).filter((file) => file.checked);
  const dispatch = useDispatchRoot();
  const handleCheckAllChange = (value: any) => {
    const fileList = files.map((item) => ({
      ...item,
      checked: value.target.checked,
    }));
    dispatch(setCurrentDisplayFiles(fileList));
  };
  const renderSelectedText = (checkedFiles: IFile[]) => {
    const files = checkedFiles.filter((file) => file.type !== "folder");
    const folders = checkedFiles.filter((file) => file.type === "folder");
    const fileTxt =
      files.length > 0
        ? `${files.length} ${files.length > 1 ? "files" : "file"}`
        : "";
    const folderTxt =
      folders.length > 0
        ? `${folders.length} ${folders.length > 1 ? "folders" : "folder"}`
        : "";
    return `${fileTxt} ${
      files.length > 0 && folders.length > 0 ? "and" : ""
    } ${folderTxt} selected`;
  };
  return (
    <>
      <div className={styles.gridViewController}>
        <Checkbox
          disabled={files.length === 0}
          checked={
            selectCheckedFiles.length === files.length &&
            selectCheckedFiles.length !== 0
          }
          onChange={handleCheckAllChange}
        />
        {selectCheckedFiles && selectCheckedFiles.length > 0 && (
          <span>{renderSelectedText(selectCheckedFiles)}</span>
        )}
        <span style={{ fontWeight: 600 }}>Name</span>
        <span style={{ fontWeight: 600 }}>Size</span>
        <span style={{ fontWeight: 600 }}>Modified</span>
      </div>
      <Divider style={{ margin: "12px 0 24px" }} />
      <div className={styles.gridViewFiles}>
        {files && files.length > 0 ? (
          files.map((file, index) => (
            <GridViewFileItem key={index} file={file} />
          ))
        ) : (
          <Space style={{ width: "100%", justifyContent: "center" }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Space>
        )}
      </div>
    </>
  );
};
