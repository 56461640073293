import React from "react";
import { RouteProps } from "react-router-dom";
import ProjectIcon from "../../image/icon/project-icon.svg";
import UserIcon from "../../image/icon/users-icon.svg";
import MyDataIcon from "../../image/icon/myData-icon.svg";
import MyShareIcon from "../../image/icon/myShare-icon.svg";
import { UserManager } from "../screen/UserManager/UserManager";
import { ProjectManager } from "../screen/projects/ProjectManager";
import DashboardIcon from "../../image/icon/dashboard-icon.svg";
import WorkflowIcon from "../../image/icon/workflow-icon.svg";
import IssuesIcon from "../../image/icon/issues-icon.svg";
import MemberIcon from "../../image/icon/member-icon.svg";
import GroupIcon from "../../image/icon/group-icon.svg";
import RecentIcon from "../../image/icon/recent-icon.svg";
import FavouriteIcon from "../../image/icon/favourite-icon.svg";
import TagIcon from "../../image/icon/tag-icon.svg";
import DeleteIcon from "../../image/icon/delete-icon.svg";
import {
  ProjectFiles,
  ProjectMembers,
} from "../screen/projects/projectDetail/index";

export const enum RootClaim {
  System = "System",
  Admin = "Admin",
  User = "User",
  All = "All",
}

export interface RouteObj {
  label: string;
  icon?: string;
  rootPermission?: RootClaim[];
  routeProps?: RouteProps;
  documentTitle?: string;
  items?: RouteObj[];
  color?: string;
  isRoot?: boolean;
  isLabelDisplay?: boolean;
}

export const Routes: RouteObj[] = [
  {
    label: "Projects",
    icon: ProjectIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/projects",
      children: <ProjectManager />,
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Users",
    icon: UserIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/user",
      children: <UserManager></UserManager>,
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "MyData",
    icon: MyDataIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/mydata",
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "MyShare",
    icon: MyShareIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/myshare",
    },
    rootPermission: [RootClaim.All],
  },
];

export const ProjectRoutes: RouteObj[] = [
  {
    label: "Dashboard",
    icon: DashboardIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/dashboard",
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Project Files",
    icon: ProjectIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/files",
      children: <ProjectFiles />,
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Workflow",
    icon: WorkflowIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/workflow",
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Issues",
    icon: IssuesIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/issues",
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Members",
    icon: MemberIcon,
    color: "#FFFFFF",
    isLabelDisplay: false,
    routeProps: {
      path: "/project/members",
      children: <ProjectMembers />,
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Groups",
    icon: GroupIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/groups",
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Recent",
    icon: RecentIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/recents",
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Favourites",
    icon: FavouriteIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/favourites",
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Tags",
    icon: TagIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/tags",
    },
    rootPermission: [RootClaim.All],
  },
  {
    label: "Deleted Files",
    icon: DeleteIcon,
    color: "#FFFFFF",
    routeProps: {
      path: "/project/trashbin",
    },
    rootPermission: [RootClaim.All],
  },
];
