import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import HeaderLogo from "../../image/icon/Header-logo.svg";
import styles from "./HeaderSection.module.css";
import { ProfileSection } from "./ProfileSection";
import { useDispatchRoot, useSelectorRoot } from "../../store/store";
import { Select } from "antd";
import { IProjectItem } from "../../common/define-types";
import { dummyProjects } from "../../dummyData/projectList";
import { BaseOptionType } from "antd/es/select";
import { setProjectSelected } from "../../store/slice/projectSlice";
import { useHistory } from "react-router-dom";
const projectsToSlcOptions = (projects: IProjectItem[]) => {
  const options: BaseOptionType[] = [];
  projects.forEach((project) => {
    options.push({
      label: project.name,
      value: project.id,
    });
  });
  return options;
};

export const HeaderSection = () => {
  const selectProject = useSelectorRoot(
    (state) => state.project.projectSelected
  );
  const history = useHistory();
  const dispatch = useDispatchRoot();
  const handleProjectChange = (value: string) => {
    dispatch(
      setProjectSelected(
        dummyProjects.filter((project) => project.id === value)[0]
      )
    );
    history.replace({ search: "" });
  };
  // const getWindowDimensions = () => {
  //   const width = typeof window !== 'undefined' ? window.innerWidth : 1000;
  //   const height = typeof window !== 'undefined' ? window.innerHeight : null;
  //   return {
  //     width,
  //     height,
  //   };
  // }
  // const [widthWin, setWitdhWin] = useState(getWindowDimensions().width);
  // window.addEventListener('resize', () => {
  //   var withWindows = getWindowDimensions().width;
  //   setWitdhWin(withWindows ?? 1000);
  // });
  return (
    <Header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <img src={HeaderLogo} alt="logo" className={styles.logo}></img>
          <span className={styles.logoName}>ANYON</span>
        </div>
        {selectProject && selectProject !== null && (
          <Select
            value={selectProject?.id}
            className={`${styles.projectSelect} projectSelect`}
            options={projectsToSlcOptions(dummyProjects)}
            onChange={handleProjectChange}
            suffixIcon={
              <DownOutlined style={{ color: "black", pointerEvents: "none" }} />
            }
          />
        )}
        <ProfileSection />
      </div>
    </Header>
  );
};
