import React, { useState, useEffect, Children } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { DashOutlined } from "@ant-design/icons";
import { IconButton } from "../../../../../components/Buttons";
import { IFile } from "../../../../../common/define-types";
import FolderIcon from "../../../../../image/icon/folder-icon.svg";
import { FileIcon } from "../../../../../components/File/FileIcon";
import { ContentMenu } from "./ContentMenu";
import { useDispatchRoot, useSelectorRoot } from "../../../../../store/store";
import {
  setCurrentDisplayFiles,
  setFolderBreadcrum,
} from "../../../../../store/slice/projectSlice";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
interface IProps {
  files: IFile[];
}

const RowWithContentMenu: React.FC<any> = ({ index, ...props }) => {
  const selectCurrentDisplayFiles =
    useSelectorRoot((state) => state.project.currentDisplayFiles) ?? [];
  const key = "data-row-key";
  const file =
    selectCurrentDisplayFiles.length > 0 &&
    selectCurrentDisplayFiles.filter((item) => item.id === props[key])[0];
  const dispatch = useDispatchRoot();
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const selectFolderBreadcrum = useSelectorRoot(
    (state) => state.project.folderBreadcrum
  );

  const handleRowDblClick = (file: IFile) => {
    if (file.type === "folder" && file) {
      const oldDir = values.dir ?? "";
      const newDir = `/${file.name}`;
      history.push({
        search: `?dir=${oldDir}${newDir}&fileId=${file.id}`,
      });
      dispatch(setFolderBreadcrum([...selectFolderBreadcrum, file.id]));
    }
  };
  if (file) {
    return (
      <ContentMenu trigger={["contextMenu"]} fileId={file.id}>
        <tr
          {...props}
          style={{ cursor: file.type === "folder" ? "pointer" : "" }}
          onDoubleClick={() => handleRowDblClick(file)}
        />
      </ContentMenu>
    );
  } else {
    return <tr {...props} />;
  }
};

export const ListViewFiles = ({ files }: IProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const dispatch = useDispatchRoot();
  const columns: ColumnsType<IFile> = [
    {
      dataIndex: "type",
      width: 40,
      render: (_, record) =>
        record.type === "folder" ? (
          <div style={{ display: "flex" }}>
            <img src={FolderIcon} alt="folder" />
          </div>
        ) : (
          <div style={{ display: "flex", width: 24 }}>
            <FileIcon fileName={record.name} size={24} />
          </div>
        ),
      //   defaultSortOrder: "descend",
      //   sorter: (a, b) => a.size - b.size,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) =>
        a.name.localeCompare(b.name, "vi-VN", { numeric: true }),
    },
    {
      title: "Size",
      dataIndex: "size",
      width: 120,
      sorter: (a, b) => {
        if (a.size && b.size) {
          return a.size.length - b.size.length;
        } else {
          return -1;
        }
      },
    },
    {
      title: "Modified",
      width: 120,
      dataIndex: "modified",
      sorter: (a, b) =>
        a.name.localeCompare(b.name, "vi-VN", { numeric: true }),
    },
    {
      dataIndex: "action",
      width: 80,
      render: (_, record) => (
        <ContentMenu fileId={record.id}>
          <IconButton
            icon={<DashOutlined />}
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </ContentMenu>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const newFiles = files.map((file) => ({
      ...file,
      checked: newSelectedRowKeys.includes(file.id),
    }));
    dispatch(setCurrentDisplayFiles(newFiles));
  };
  useEffect(() => {
    const temp: React.Key[] = [];
    files.forEach((file) => file.checked && temp.push(file.id));
    setSelectedRowKeys(temp);
  }, [files]);
  return (
    <Table
      rowKey={"id"}
      columns={columns}
      className="listView-table"
      pagination={{
        position: ["bottomRight"],
        defaultPageSize: 20,
        hideOnSinglePage: true,
      }}
      rowSelection={{
        selectedRowKeys,
        fixed: true,
        type: "checkbox",
        onChange: onSelectChange,
      }}
      dataSource={files}
      style={{ marginTop: 10 }}
      components={{
        body: {
          row: RowWithContentMenu,
        },
      }}
    />
  );
};
