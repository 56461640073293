import React from "react";
import { List } from "antd";
import { ProjectItem } from "./ProjectItem";
import { dummyProjects } from "../../../dummyData/projectList";

export const ProjectManager = () => {
  return (
    <>
      <List
        split={false}
        itemLayout="vertical"
        size="large"
        dataSource={dummyProjects}
        renderItem={(item) => <ProjectItem item={item} key={item.id} />}
      />
    </>
  );
};
