import { Modal } from "antd";
import React, { FC } from "react";

interface IAddMemberModal {
  open: boolean;
  handleClose: () => void;
}
export const AddMemberModal: FC<IAddMemberModal> = ({ open, handleClose }) => {
  return (
    <Modal
      centered
      open={open}
      onCancel={handleClose}
      title={"Add Or Remove Member"}
    >
      {" "}
    </Modal>
  );
};
