import React, { useEffect, useState } from "react";
import { Layout, Menu, Progress, Typography } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelectorRoot } from "../../store/store";
import { setCollap } from "../../store/slice/controlSlice";
import { RouteObj } from "../MenuRoutes/Routes";
import CaratRight from "remixicon-react/ArrowRightSLineIcon";
import CaratLeft from "remixicon-react/ArrowLeftSLineIcon";
import styles from "./LeftMenu.module.css";
import { FloatingActionButton } from "../../components/Buttons/FloatingActionButton";
import StorageIcon from "../../image/icon/storage-icon.svg";

const { Text } = Typography;

interface IProps {
  routes: RouteObj[];
}
export const LeftMenu = ({ routes }: IProps) => {
  const MAX_STORAGE = 10000;
  const CURRENT_USAGE = 982;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const collapsed = useSelectorRoot((state) => state.control.isCollap);
  const onCollapse = () => {
    dispatch(setCollap(!collapsed));
  };
  const getWindowDimensions = () => {
    const width = typeof window !== "undefined" ? window.innerWidth : 1000;
    const height = typeof window !== "undefined" ? window.innerHeight : null;
    return {
      width,
      height,
    };
  };
  useEffect(() => {
    if (getWindowDimensions().width < 1000) dispatch(setCollap(true));
  }, []);
  const [widthWin, setWitdhWin] = useState(getWindowDimensions().width);
  const navigate = ({ key }: any) => {
    if (key !== "storage") {
      history.push(key);
    }
  };
  window.addEventListener("resize", () => {
    var withWindows = getWindowDimensions().width;
    setWitdhWin(withWindows ?? 1000);
  });

  const activeKey = (): string => {
    for (var route of routes) {
      if (location.pathname.startsWith(`${route.routeProps?.path as string}`)) {
        return route.routeProps?.path as string;
      }
      for (var child of route.items ?? []) {
        if (
          location.pathname.startsWith(`${child.routeProps?.path as string}`)
        ) {
          return child.routeProps?.path as string;
        }
      }
    }
    return "/";
  };
  const rederItem = (route: RouteObj) => (
    <Menu.Item
      key={route.routeProps ? (route.routeProps.path as string) : undefined}
      icon={route.icon ? <img src={route.icon} alt="icon"></img> : undefined}
    >
      {!collapsed ? (
        <Text
          style={{
            marginLeft: route.icon || route.isRoot ? 0 : 30,
            color:
              activeKey() === (route.routeProps?.path as string)
                ? "#FFFFFF"
                : route.color,
          }}
        >
          {route.label}
        </Text>
      ) : (
        <Text
          style={{
            color:
              activeKey() === (route.routeProps?.path as string)
                ? "#FFFFFF"
                : route.color,
          }}
        >
          {route.label}
        </Text>
      )}
    </Menu.Item>
  );
  const render = () => (
    <>
      {widthWin >= 1000 ? (
        <FloatingActionButton top="33px" right="-14px" onClick={onCollapse}>
          {collapsed ? (
            <CaratRight style={{ verticalAlign: "middle" }} />
          ) : (
            <CaratLeft style={{ verticalAlign: "middle" }} />
          )}
        </FloatingActionButton>
      ) : (
        <FloatingActionButton
          style={{ position: "fixed", top: "16px", left: "16px" }}
          onClick={onCollapse}
        >
          {collapsed ? (
            <CaratRight style={{ verticalAlign: "middle" }} />
          ) : (
            <CaratLeft style={{ verticalAlign: "middle" }} />
          )}
        </FloatingActionButton>
      )}
      <Menu
        onSelect={navigate}
        className={`${styles.LeftMenu} leftMenu`}
        selectedKeys={[activeKey()]}
        // inlineCollapsed={collapsed}
        mode="inline"
      >
        {routes
          .filter((route) => route.routeProps?.path !== "/project/trashbin")
          .map((route) => (
            <>{rederItem(route)}</>
          ))}
      </Menu>
      <Menu
        onSelect={navigate}
        className={`${styles.LeftMenu} leftMenu`}
        selectedKeys={[activeKey()]}
        // inlineCollapsed={collapsed}
        mode="inline"
      >
        {routes
          .filter((route) => route.routeProps?.path === "/project/trashbin")
          .map((route) => (
            <>{rederItem(route)}</>
          ))}
        <Menu.Item
          key="storage"
          className="noHover"
          icon={<img src={StorageIcon} alt="icon"></img>}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ color: "#FFFFFF" }}>982.3MB of 10GB</Text>
            <Progress
              percent={(CURRENT_USAGE / MAX_STORAGE) * 100}
              showInfo={false}
              trailColor="white"
              strokeColor={"#2196F3"}
            />
          </div>
        </Menu.Item>
      </Menu>
    </>
  );

  return widthWin >= 1000 ? (
    <Layout.Sider
      collapsible={false}
      width={collapsed ? 50 : 260}
      collapsed={collapsed}
      className={`${styles.sider} sider`}
    >
      {render()}
    </Layout.Sider>
  ) : (
    <Layout.Sider
      width={200}
      collapsedWidth={0}
      collapsed={collapsed}
      className={`${styles.sider} sider`}
      style={{
        position: "fixed",
        height: "calc(100% - 64px)",
        zIndex: 7,
      }}
    >
      {render()}
    </Layout.Sider>
  );
};
