import { Empty } from "antd";
import React from "react";

export const ActivityTab = () => {
  return (
    <div>
      <Empty description="No activity yet" />
    </div>
  );
};
