import React from "react";
import UnknownFile from "../../image/icon/unknown-file.svg";
import { FILE_EXTENSION } from "../../common/constants";
import CssFile from "../../image/icon/css.svg";
import DatFile from "../../image/icon/dat.svg";
import DllFile from "../../image/icon/dll.svg";
import DmgFile from "../../image/icon/dmg.svg";
import DocFile from "../../image/icon/doc.svg";
import EpsFile from "../../image/icon/eps.svg";
import FlaFile from "../../image/icon/fla.svg";
import FlvFile from "../../image/icon/flv.svg";
import GifFile from "../../image/icon/gif.svg";
import HtmlFile from "../../image/icon/html.svg";
import InddFile from "../../image/icon/indd.svg";
import IsoFile from "../../image/icon/iso.svg";
import JpgFile from "../../image/icon/jpg.svg";
import JsFile from "../../image/icon/js.svg";
import MidiFile from "../../image/icon/midi.svg";
import MovFile from "../../image/icon/mov.svg";
import Mp3File from "../../image/icon/mp3.svg";
import MpgFile from "../../image/icon/mpg.svg";
import PdfFile from "../../image/icon/pdf.svg";
import PhpFile from "../../image/icon/php.svg";
import PngFile from "../../image/icon/png.svg";
import PptFile from "../../image/icon/ppt.svg";
import PsFile from "../../image/icon/ps.svg";
import PsdFile from "../../image/icon/psd.svg";
import RawFile from "../../image/icon/raw.svg";
import SqlFile from "../../image/icon/sql.svg";
import SvgFile from "../../image/icon/svg.svg";
import TifFile from "../../image/icon/tif.svg";
import TxtFile from "../../image/icon/txt.svg";
import WmwFile from "../../image/icon/wmv.svg";
import XlsFile from "../../image/icon/xls.svg";
import XmlFile from "../../image/icon/xml.svg";
import ZipFile from "../../image/icon/zip.svg";
import ThreeDsFile from "../../image/icon/threeDs.svg";
import AacFile from "../../image/icon/aac.svg";
import AiFile from "../../image/icon/ai.svg";
import AviFile from "../../image/icon/avi.svg";
import BmpFile from "../../image/icon/bmp.svg";
import CadFile from "../../image/icon/cad.svg";
import CdrFile from "../../image/icon/cdr.svg";
interface IProps {
  fileName: string;
  size?: number;
  style?: React.CSSProperties;
}
const renderIconSrc = (fileName: string) => {
  const index = fileName.indexOf(".");
  const fileType = fileName.slice(index + 1);
  switch (fileType.toUpperCase()) {
    case FILE_EXTENSION.CSS:
      return CssFile;
    case FILE_EXTENSION.DAT:
      return DatFile;
    case FILE_EXTENSION.DLL:
      return DllFile;
    case FILE_EXTENSION.DMG:
      return DmgFile;
    case FILE_EXTENSION.DOC:
      return DocFile;
    case FILE_EXTENSION.EPS:
      return EpsFile;
    case FILE_EXTENSION.FLA:
      return FlaFile;
    case FILE_EXTENSION.FLV:
      return FlvFile;
    case FILE_EXTENSION.GIF:
      return GifFile;
    case FILE_EXTENSION.HTML:
      return HtmlFile;
    case FILE_EXTENSION.INDD:
      return InddFile;
    case FILE_EXTENSION.ISO:
      return IsoFile;
    case FILE_EXTENSION.JPG:
      return JpgFile;
    case (FILE_EXTENSION.JS, FILE_EXTENSION.JSX):
      return JsFile;
    case FILE_EXTENSION.MIDI:
      return MidiFile;
    case FILE_EXTENSION.MOV:
      return MovFile;
    case FILE_EXTENSION.MP3:
      return Mp3File;
    case FILE_EXTENSION.MPG:
      return MpgFile;
    case FILE_EXTENSION.PDF:
      return PdfFile;
    case FILE_EXTENSION.PHP:
      return PhpFile;
    case FILE_EXTENSION.PNG:
      return PngFile;
    case FILE_EXTENSION.PPT:
      return PptFile;
    case FILE_EXTENSION.PS:
      return PsFile;
    case FILE_EXTENSION.PSD:
      return PsdFile;
    case FILE_EXTENSION.RAW:
      return RawFile;
    case FILE_EXTENSION.SQL:
      return SqlFile;
    case FILE_EXTENSION.SVG:
      return SvgFile;
    case FILE_EXTENSION.TIF:
      return TifFile;
    case FILE_EXTENSION.TXT:
      return TxtFile;
    case FILE_EXTENSION.WMV:
      return WmwFile;
    case FILE_EXTENSION.XLS:
      return XlsFile;
    case FILE_EXTENSION.XML:
      return XmlFile;
    case FILE_EXTENSION.ZIP:
      return ZipFile;
    case FILE_EXTENSION.THREEDS:
      return ThreeDsFile;
    case FILE_EXTENSION.AAC:
      return AacFile;
    case FILE_EXTENSION.AI:
      return AiFile;
    case FILE_EXTENSION.AVI:
      return AviFile;
    case FILE_EXTENSION.BMP:
      return BmpFile;
    case FILE_EXTENSION.CAD:
      return CadFile;
    case FILE_EXTENSION.CDR:
      return CdrFile;
    default:
      return UnknownFile;
  }
};
export const FileIcon = ({ fileName, size = 26 }: IProps) => {
  return (
    <img
      src={renderIconSrc(fileName)}
      alt="file icon"
      style={{
        width: "100%",
        height: "auto",
        maxWidth: size,
        maxHeight: size,
        objectFit: "cover",
      }}
    />
  );
};
