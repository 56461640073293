export const dummyProjects = [
  {
    id: "1",
    imgUrl:
      "https://cdn.dribbble.com/userupload/6332537/file/original-0ff9d929518ec45be8be59a4702f7053.jpg?compress=1&resize=640x480&vertical=top",
    name: "AnyOn - Giải pháp lưu trữ - Quản lý - Khai thác dữ liệu BIM",
    ownerEmail: "cuong_dv@hicas.com.vn",
  },
  {
    id: "2",
    imgUrl:
      "https://cdn.dribbble.com/userupload/6332149/file/original-325687c78d57ca22df94e60377e47371.jpg?compress=1&resize=640x480&vertical=top",
    name: "CDCC",
    ownerEmail: "cuong_dv@hicas.com.vn",
  },
  {
    id: "3",
    imgUrl:
      "https://cdn.dribbble.com/userupload/6335757/file/original-9c233c93c8767a9179288e00ea453d47.png?compress=1&resize=450x338&vertical=top",
    name: "Nhà máy HITACHI 3",
    ownerEmail: "cuong_dv@hicas.com.vn",
  },
  {
    id: "4",
    imgUrl:
      "https://cdn.dribbble.com/userupload/6341217/file/original-67f5928b9e645c83080db9b0c5ade927.jpg?compress=1&resize=640x480&vertical=top",
    name: "Nhà máy Mascot Hải Dương",
    ownerEmail: "cuong_dv@hicas.com.vn",
  },
  {
    id: "5",
    name: "THILAWA WAREHOUSE",
    ownerEmail: "cuong_dv@hicas.com.vn",
  },
  {
    id: "6",
    name: "All Shares ",
    ownerEmail: "cuong_dv@hicas.com.vn",
  },
  {
    id: "7",
    name: "TCS",
    ownerEmail: "cuong_dv@hicas.com.vn",
  },
];
