import React, { FC, useState } from "react";
import styles from "./ProjectMembers.module.css";
import { MEMBER_VIEW } from "../../../../../common/constants";
import Segmented, { SegmentedValue } from "antd/es/segmented";
import { Typography } from "antd";
import { MemberTable } from "./MemberTable";
import { RoleTable } from "./RoleTable";
export const ProjectMembers: FC = () => {
  const [activeView, setActiveView] = useState<SegmentedValue>(
    MEMBER_VIEW.MEMBERS
  );
  const handleViewChange = (value: SegmentedValue) => {
    setActiveView(value);
  };
  const renderView = () => {
    switch (activeView) {
      case MEMBER_VIEW.ROLES:
        return <RoleTable />;
      default:
        return <MemberTable />;
    }
  };
  return (
    <div className={styles.memberContainer}>
      <Segmented
        value={activeView}
        onChange={handleViewChange}
        style={{ backgroundColor: "#f3f3f7" }}
        options={[
          {
            label: (
              <Typography.Text style={{ fontSize: 20 }} strong>
                {MEMBER_VIEW.MEMBERS}
              </Typography.Text>
            ),
            value: MEMBER_VIEW.MEMBERS,
          },
          {
            label: (
              <Typography.Text style={{ fontSize: 20 }} strong>
                {MEMBER_VIEW.ROLES}
              </Typography.Text>
            ),
            value: MEMBER_VIEW.ROLES,
          },
        ]}
      />

      <div className={styles.tableContainer}>{renderView()}</div>
    </div>
  );
};
