import { Button } from "antd";
import React from "react";

interface IIconButton {
  style?: React.CSSProperties;
  icon: any;
  onClick?: () => void;
  size?: number;
  onContextMenu?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const IconButton = ({
  style,
  icon,
  onClick,
  onContextMenu,
  size = 36,
}: IIconButton) => {
  return (
    <Button
      icon={icon}
      size={"small"}
      style={{
        width: size,
        height: size,
        backgroundColor: "#d9d9d96b",
        borderRadius: "50%",
        ...style,
      }}
      onClick={onClick}
      onContextMenu={onContextMenu}
    />
  );
};
