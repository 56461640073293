import React from "react";

interface IContentMenuItem {
  imgSrc: string;
  label: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}
export const ContentMenuItem = ({
  imgSrc,
  label,
  style,
  onClick,
}: IContentMenuItem) => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: 10,
      }}
      onClick={onClick}
    >
      <img
        style={{ width: "100%", height: "auto", maxWidth: "16px" }}
        src={imgSrc}
        alt="icon"
      />
      <span style={{ fontWeight: 600 }}>{label}</span>
    </div>
  );
};
