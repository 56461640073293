import { Typography, Checkbox, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { MEMBER_ROLE } from "common/constants";
import { IRole } from "common/define-types";
import React, { useState } from "react";
import styles from "./ProjectMembers.module.css";
import { IconButton } from "components/Buttons";
import { PlusOutlined } from "@ant-design/icons";
import { AddRoleModal } from "./AddRoleModal";
import { SystemTitle } from "./SystemTitle";

export const RoleTable = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const permissionItemWidth = 60;
  const dataSource: IRole[] = [
    { name: MEMBER_ROLE.ADMIN, description: "", id: MEMBER_ROLE.ADMIN },
    { name: MEMBER_ROLE.MANAGER, description: "", id: MEMBER_ROLE.MANAGER },
    { name: MEMBER_ROLE.LEADER, description: "", id: MEMBER_ROLE.LEADER },
    { name: MEMBER_ROLE.TEAM, description: "", id: MEMBER_ROLE.TEAM },
    { name: MEMBER_ROLE.MEMBER, description: "", id: MEMBER_ROLE.MEMBER },
  ];
  const roleSystems = [
    { label: "CRR", value: "crr", tooltip: "Create role" },
    { label: "ARM", value: "arm", tooltip: "Add/Remove members" },
    { label: "CRG", value: "crg", tooltip: "Create group" },
    { label: "CHR", value: "chr", tooltip: "Change role" },
    { label: "CHP", value: "chp", tooltip: "Change permission" },
    { label: "CRI", value: "cri", tooltip: "Create issue" },
  ];
  const permissions = [
    { label: "Access", value: "access" },
    { label: "Download", value: "download" },
    { label: "Edit", value: "edit" },
    { label: "Create", value: "create" },
    { label: "Delete", value: "delete" },
    { label: "Share", value: "share" },
    { label: "Comment", value: "comment" },
  ];
  const columns: ColumnsType<IRole> = [
    {
      title: "Role Name",
      dataIndex: "name",
      sorter: (a, b) =>
        a.name.localeCompare(b.name, "vi-VN", { numeric: true }),
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "System",
      dataIndex: "system",
      className: styles.borderRight,
      children: roleSystems.map((system) => ({
        title: <SystemTitle system={system} />,
        align: "center",
        className: system.label === "CRI" ? styles.borderRight : "",
        dataIndex: system.value,
        width: permissionItemWidth / 1.4,
        render: (_, record) => <Checkbox />,
      })),
    },
    {
      title: "Permission",
      dataIndex: "action",
      children: permissions.map((per) => ({
        title: (
          <Typography.Text className={styles.permissionItemTitle}>
            {per.label}
          </Typography.Text>
        ),
        dataIndex: per.value,
        align: "center",
        ellipsis: true,
        width: permissionItemWidth,
        render: (_, record) => <Checkbox />,
      })),
    },
    {
      title: (
        <IconButton
          onClick={() => setIsAddModalOpen(true)}
          icon={<PlusOutlined />}
        />
      ),
      dataIndex: "addRole",
      width: 50,
    },
  ];
  return (
    <>
      <AddRoleModal
        open={isAddModalOpen}
        handleClose={() => setIsAddModalOpen(false)}
      />
      <Table
        rowKey={"id"}
        className={`${styles.memberTable} memberTable`}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          defaultCurrent: 1,
          total: dataSource.length,
          showSizeChanger: true,
        }}
        scroll={{ x: 1200, y: 650 }}
      />
    </>
  );
};
