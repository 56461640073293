import { MEMBER_ROLE } from "../common/constants";

const roles = [
  MEMBER_ROLE.ADMIN,
  MEMBER_ROLE.LEADER,
  MEMBER_ROLE.MANAGER,
  MEMBER_ROLE.TEAM,
  MEMBER_ROLE.MEMBER,
];
const groups = ["Electrical", "Equipment", "Kết cấu", "Project"];
const generateProjectMembers = () => {
  return new Array(7).fill(null).map((_, prjIndex) => ({
    prjId: `${prjIndex + 1}`,
    members: new Array(50).fill(null).map((_, index) => ({
      id: `user${prjIndex}-${index}`,
      userName: `user${prjIndex}_${index}@gmail.com`,
      groups: [groups[index % 4], groups[(index * 2) % 4]],
      role: roles[index % 5],
    })),
  }));
};
export const dummyProjectMembers = generateProjectMembers();
