import { Form, Input, Modal } from "antd";
import React, { FC } from "react";

interface IAddRoleModal {
  open: boolean;
  handleClose: () => void;
}
export const AddRoleModal: FC<IAddRoleModal> = ({ open, handleClose }) => {
  return (
    <Modal
      okText="Done"
      centered
      open={open}
      onCancel={handleClose}
      onOk={handleClose}
      title={"Add New Role"}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
        <Form.Item label={"Role Name"}>
          <Input />
        </Form.Item>
        <Form.Item label={"Role Description"}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
