import React from "react";
import { Route, Switch } from "react-router-dom";

import { ProjectLayout } from "../layout/ProjectLayout";
import { AdminLayout } from "../layout/AdminLayout";

export const Main = () => {
  return (
    <Switch>
      <Route path="/project/:path?" exact>
        <ProjectLayout />
      </Route>
      <Route path="/">
        <AdminLayout />
      </Route>
    </Switch>
  );
};
