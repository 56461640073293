import React from "react";
import styles from "./ProjectMembers.module.css";
import { Typography, Tooltip } from "antd";

interface IProps {
  system: {
    label: string;
    tooltip?: string;
  };
}
export const SystemTitle = ({ system }: IProps) => {
  return (
    <Typography.Text className={styles.permissionItemTitle}>
      <Tooltip title={system.tooltip}>{system.label}</Tooltip>
    </Typography.Text>
  );
};
