import React, { FC, useEffect, useState } from "react";
import { Breadcrumb, Layout, LayoutProps, Tooltip, Typography } from "antd";
import { setTitle } from "../../store/slice/controlSlice";
import { useSelectorRoot, useDispatchRoot } from "../../store/store";
import styles from "./Page.module.css";
import { PlusOutlined } from "@ant-design/icons";
import { IconButton } from "../../components/Buttons";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import RightArrow from "../../image/icon/chevron-right.svg";
import queryString from "query-string";
import { UploadMenu } from "./UploadMenu";
import Link from "antd/es/typography/Link";
import { setFolderBreadcrum } from "store/slice/projectSlice";
const { Content } = Layout;
interface PageProps {
  title?: any;
}

export const Page: FC<LayoutProps & PageProps> = ({
  children,
  title,
  className,
  ...props
}) => {
  // const { user } = useSelectorRoot((x) => x.login);
  const selectProject = useSelectorRoot(
    (state) => state.project.projectSelected
  );
  const selectFolderBreadcrum =
    useSelectorRoot((state) => state.project.folderBreadcrum) ?? [];
  const dispatch = useDispatchRoot();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [breadcrums, setBreadcrums] = useState([]);
  const directory: any = values.dir;
  const location = useLocation();
  const history = useHistory();
  const handleBreadcrums = () => {
    if (directory !== "" && directory) {
      const filesName = directory.slice(1).split("/");
      let url = "";
      setBreadcrums(
        filesName.map((name: string, index: number) => {
          if (index === filesName.length - 1) {
            return {
              title: name,
            };
          } else {
            url = url + `/${name}`;
            const dir = url;
            const fileId = selectFolderBreadcrum[index];
            const folderBreadcrum = selectFolderBreadcrum.slice(0, index + 1);

            return {
              title: (
                <Link
                  onClick={() => {
                    handleBreadcrumClick(dir, fileId, folderBreadcrum);
                  }}
                >
                  {name}
                </Link>
              ),
            };
          }
        })
      );
    } else {
      setBreadcrums([]);
    }
  };

  const handleBreadcrumClick = (
    dir: string,
    fileId: string,
    folderBreadcrum: string[]
  ) => {
    console.log(dir);

    history.push({ search: `?dir=${dir}&fileId=${fileId}` });
    dispatch(setFolderBreadcrum(folderBreadcrum));
  };
  useEffect(() => {
    handleBreadcrums();
  }, [search]);

  useEffect(() => {
    if (title) {
      dispatch(setTitle(title));
    }
  }, [title]);

  return (
    <>
      <Layout {...props}>
        <Content className={styles.contentPage}>
          {location.pathname === "/project/files" ? (
            <div className={styles.titleContainer}>
              {selectProject && (
                <>
                  <Tooltip title={selectProject.name}>
                    <Typography.Title
                      level={3}
                      className={`${styles.pageTitle} ${
                        (!title || title === "") && styles.invincible
                      }`}
                    >
                      <NavLink
                        style={{ color: "#363545" }}
                        to={"/project/files"}
                      >
                        {selectProject.name}
                      </NavLink>
                    </Typography.Title>
                  </Tooltip>
                  {directory && directory !== "" && (
                    <>
                      <img src={RightArrow} alt="arrow" />
                      <Breadcrumb
                        className={"fileBreadcrum"}
                        items={breadcrums}
                        separator={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <img src={RightArrow} alt="arrow" />
                          </div>
                        }
                      />
                    </>
                  )}
                  <UploadMenu>
                    <IconButton icon={<PlusOutlined />} />
                  </UploadMenu>
                </>
              )}
            </div>
          ) : (
            <Tooltip title={title}>
              <Typography.Title
                level={3}
                className={`${styles.pageTitle} ${
                  (!title || title === "") && styles.invincible
                }`}
              >
                {title}
              </Typography.Title>
            </Tooltip>
          )}
          <div className={styles.mainContent}>{children}</div>
        </Content>
      </Layout>
    </>
  );
};
