import { Drawer, LayoutProps, Space, Typography, Tabs } from "antd";
import React, { FC } from "react";
import { IconButton } from "../../../../../../components/Buttons";
import { CloseOutlined } from "@ant-design/icons";
import { FileIcon } from "../../../../../../components/File/FileIcon";
import { IFile } from "../../../../../../common/define-types";
import type { TabsProps } from "antd";
import FolderIcon from "../../../../../../image/icon/folder-icon.svg";
import { ActivityTab } from "./ActivityTab";
import { CommentTab } from "./CommentTab";
import { VersionTab } from "./VersionTab";
import { SharingTab } from "./SharingTab";

interface IFileDetail {
  detailFile: IFile | null;
  onClose: () => void;
}

export const FileDetail: FC<LayoutProps & IFileDetail> = ({
  detailFile,
  onClose,
}) => {
  const items: TabsProps["items"] = [
    {
      key: "activity",
      label: `Activity`,
      children: <ActivityTab />,
    },
    {
      key: "comments",
      label: `Comments`,
      children: <CommentTab />,
    },
    {
      key: "sharing",
      label: `Sharing`,
      children: <SharingTab />,
    },
    {
      key: "versions",
      label: `Versions`,
      children: <VersionTab />,
    },
  ];
  return (
    <Drawer
      destroyOnClose={true}
      title={
        <div style={{ display: "flex", gap: "14px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {detailFile?.type === "folder" ? (
              <img src={FolderIcon} alt="folder" />
            ) : (
              <FileIcon size={24} fileName={detailFile?.name ?? ""} />
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text strong style={{ fontSize: 18 }}>
              {detailFile?.name}
            </Typography.Text>
            <div style={{ display: "flex", gap: "14px" }}>
              <Typography.Text style={{ opacity: 0.6 }}>
                {detailFile?.size}
              </Typography.Text>
              <Typography.Text style={{ opacity: 0.6 }}>
                {detailFile?.modified}
              </Typography.Text>
            </div>
          </div>
        </div>
      }
      placement={"right"}
      size="large"
      closeIcon={null}
      onClose={onClose}
      open={detailFile !== null}
      className={`fileDetail`}
      extra={
        <Space>
          <IconButton
            size={24}
            onClick={() => onClose()}
            icon={<CloseOutlined style={{ fontSize: "12px" }} />}
          />
        </Space>
      }
    >
      <Tabs
        defaultActiveKey="activity"
        items={items}
        tabBarStyle={{ width: "100%" }}
      />
    </Drawer>
  );
};
