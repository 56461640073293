import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFile, IMember, IProjectItem } from '../../common/define-types';

export interface ProjectState {
  projectSelected: IProjectItem | null;
  currentDisplayFiles: IFile[];
  projectFile: IFile[];
  detailFileSelected: IFile | null;
  projectMembers: IMember[];
  folderBreadcrum: string[]
}

const initialState: ProjectState = {
    projectSelected: null,
    currentDisplayFiles: [],
    detailFileSelected: null,
    projectFile: [],
    projectMembers: [],
    folderBreadcrum: [],
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setProjectSelected: (state, action: PayloadAction<IProjectItem | null>) => {
      state.projectSelected = action.payload;
    },
    setCurrentDisplayFiles: (state, action: PayloadAction<IFile[]>) => {
      state.currentDisplayFiles = action.payload;
    },
    setProjectFiles: (state, action: PayloadAction<IFile[]>) => {
      state.projectFile = action.payload;
    },
    setDetailFileSelected: (state, action: PayloadAction<IFile | null>) => {
      state.detailFileSelected = action.payload;
    },
    setProjectMembers: (state, action: PayloadAction<IMember[]>) => {
      state.projectMembers = action.payload;
    },
    setFolderBreadcrum: (state, action: PayloadAction<string[]>) => {
      state.folderBreadcrum = action.payload;
    },
  },
});

export const { 
  setProjectSelected, 
  setCurrentDisplayFiles, 
  setDetailFileSelected, 
  setProjectFiles, 
  setProjectMembers,
  setFolderBreadcrum,  
} = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
