import React, { useEffect, useState } from "react";
import { Avatar, Checkbox, Select, Table, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { IMember } from "common/define-types";
import { MEMBER_ROLE } from "common/constants";
import styles from "./ProjectMembers.module.css";
import AddMemberIcon from "image/icon/add-member-icon.svg";
import { dummyProjectMembers } from "dummyData/projectMembers";
import DefaultAvatar from "image/default_avatar.png";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { setProjectMembers } from "store/slice/projectSlice";
import { AddMemberModal } from "./AddMemberModal";

export const MemberTable = () => {
  const permissions = [
    { label: "Access", value: "access" },
    { label: "Download", value: "download" },
    { label: "Edit", value: "edit" },
    { label: "Create", value: "create" },
    { label: "Delete", value: "delete" },
    { label: "Share", value: "share" },
    { label: "Comment", value: "comment" },
  ];
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const permissionItemWidth = 60;
  const dispatch = useDispatchRoot();
  const selectProjectSelected = useSelectorRoot(
    (state) => state.project.projectSelected
  );
  const selectProjectMembers = useSelectorRoot(
    (state) => state.project.projectMembers
  );

  useEffect(() => {
    if (selectProjectSelected) {
      const members =
        dummyProjectMembers.filter(
          (item) => item.prjId === selectProjectSelected.id
        )[0].members ?? [];
      dispatch(setProjectMembers(members));
    }
  }, [selectProjectSelected, dispatch]);

  const handleRoleChange = (memberId: string, value: string) => {
    if (selectProjectMembers) {
      const newArrays = selectProjectMembers.map((member) => ({
        ...member,
        role: member.id === memberId ? value : member.role,
      }));
      dispatch(setProjectMembers(newArrays));
    }
  };
  const columns: ColumnsType<IMember> = [
    {
      dataIndex: "avatar",
      width: 50,
      render: (_, record) => <Avatar size={40} src={DefaultAvatar} />,
    },
    {
      title: "Username",
      dataIndex: "userName",
      sorter: (a, b) =>
        a.userName.localeCompare(b.userName, "vi-VN", { numeric: true }),
    },
    {
      title: "Groups",
      dataIndex: "groups",
      sorter: (a, b) => a.groups.length - b.groups.length,
      render: (_, record) => <>{record.groups.map((group) => `${group},`)}</>,
    },
    {
      title: "Roles",
      dataIndex: "role",
      width: 120,
      sorter: (a, b) =>
        a.role.localeCompare(b.role, "vi-VN", { numeric: true }),
      render: (_, record) => (
        <Select
          style={{ width: 100 }}
          value={record.role ?? MEMBER_ROLE.MEMBER}
          onChange={(value) => handleRoleChange(record.id, value)}
          suffixIcon={
            <DownOutlined style={{ color: "black", pointerEvents: "none" }} />
          }
          options={[
            { value: MEMBER_ROLE.ADMIN, label: MEMBER_ROLE.ADMIN },
            { value: MEMBER_ROLE.MANAGER, label: MEMBER_ROLE.MANAGER },
            { value: MEMBER_ROLE.LEADER, label: MEMBER_ROLE.LEADER },
            { value: MEMBER_ROLE.MEMBER, label: MEMBER_ROLE.MEMBER },
          ]}
        />
      ),
    },
    {
      title: "Permission",
      dataIndex: "action",
      children: permissions.map((per) => ({
        title: (
          <Typography.Text className={styles.permissionItemTitle}>
            {per.label}
          </Typography.Text>
        ),
        dataIndex: per.value,
        align: "center",
        width: permissionItemWidth,
        ellipsis: true,
        render: (_, record) => <Checkbox />,
      })),
    },
    {
      title: (
        <div
          className={styles.iconContainer}
          onClick={() => setIsAddModalOpen(true)}
        >
          <img src={AddMemberIcon} alt="add member" />
        </div>
      ),
      dataIndex: "addMember",
      width: 50,
    },
  ];
  return (
    <>
      <AddMemberModal
        open={isAddModalOpen}
        handleClose={() => setIsAddModalOpen(false)}
      />
      <Table
        rowKey={"id"}
        className={`${styles.memberTable} memberTable`}
        columns={columns}
        dataSource={selectProjectMembers}
        pagination={{
          defaultPageSize: 10,
          defaultCurrent: 1,
          total: selectProjectMembers.length,
          showSizeChanger: true,
        }}
        scroll={{ x: 1200, y: 650 }}
      />
    </>
  );
};
