import { Input, Typography } from "antd";
import React from "react";
import ShareIcon from "../../../../../../image/icon/share-icon.svg";
import styles from "./FileDetail.module.css";
import {
  PlusOutlined,
  DownOutlined,
  CopyOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ShareLinkIcon from "../../../../../../image/icon/link-icon.svg";
export const SharingTab = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Input
        placeholder="Name, email or Cloud ID ..."
        suffix={<SearchOutlined />}
        style={{ borderRadius: 4, marginBottom: 10 }}
      />
      <div className={styles.shareItem}>
        <div className={styles.iconContainer}>
          <img src={ShareLinkIcon} alt="share" />
        </div>
        <div className={styles.itemContent}>
          <Typography.Text strong>Share Link</Typography.Text>
        </div>
        <PlusOutlined />
      </div>
      <div className={styles.shareItem}>
        <div className={styles.iconContainer}>
          <img src={ShareIcon} className={styles.icon} alt="share" />
        </div>
        <div className={styles.itemContent}>
          <Typography.Text strong>Others with access</Typography.Text>
        </div>
        <DownOutlined />
      </div>
      <div className={styles.shareItem}>
        <div className={styles.iconContainer}>
          <img src={ShareIcon} alt="share" className={styles.icon} />
        </div>
        <div className={styles.itemContent}>
          <Typography.Text strong>Internal link</Typography.Text>
          <Typography.Text style={{ opacity: 0.6 }}>
            Only works for users with access to this file
          </Typography.Text>
        </div>
        <CopyOutlined />
      </div>
      <div className={styles.shareItem}>
        <div className={styles.iconContainer}>
          <img src={ShareIcon} alt="share" className={styles.icon} />
        </div>
        <div className={styles.itemContent}>
          <Typography.Text italic>Add to a project</Typography.Text>
          <Typography.Text style={{ opacity: 0.6 }}>
            Connect items to a project to make them easier to find
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
