import React, { useEffect } from "react";
import { Layout } from "antd";
import { Redirect, Switch } from "react-router-dom";
import { RootClaim, Routes } from "../MenuRoutes/Routes";
import { PrivateRoute } from "../../components/Routes";
import { WaitOverlay } from "../../components/overlay/WaitOverlay";
import { LeftMenu } from "../leftMenu/LeftMenu";
import { useDispatch } from "react-redux";
import { setProjectSelected } from "../../store/slice/projectSlice";

const { Content } = Layout;
export const AdminLayout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setProjectSelected(null));
  }, []);
  const renderMain = () => {
    const routerProps = [...Routes];
    return routerProps.map(({ label, rootPermission, routeProps }) => (
      <PrivateRoute
        allowedRoles={[...(rootPermission ?? [RootClaim.All])]}
        key={`${label}`}
        label={label}
        {...routeProps}
      />
    ));
  };
  return (
    <WaitOverlay>
      <Layout style={{ height: "100%" }}>
        <LeftMenu routes={Routes} />
        <Content style={{ backgroundColor: "#f3f3f7" }}>
          <Switch>
            {renderMain()}
            <Redirect to="/projects" />
          </Switch>
        </Content>
      </Layout>
    </WaitOverlay>
  );
};
