import React, { FC } from "react";
import PermissionIcon from "../../../../../image/icon/permission-icon.svg";
import ShareIcon from "../../../../../image/icon/share-icon.svg";
import CommentIcon from "../../../../../image/icon/comment-icon.svg";
import AddFavouriteIcon from "../../../../../image/icon/add-favourite-icon.svg";
import DetailIcon from "../../../../../image/icon/detail-icon.svg";
import RenameIcon from "../../../../../image/icon/rename-icon.svg";
import MoveCopyIcon from "../../../../../image/icon/move-copy-icon.svg";
import DownloadIcon from "../../../../../image/icon/download-icon.svg";
import DeleteIcon from "../../../../../image/icon/deleteFolder-icon.svg";
import { Dropdown, LayoutProps } from "antd";
import type { MenuProps } from "antd";
import { ContentMenuItem } from "./ContentMenuItem";
import { IFile } from "../../../../../common/define-types";
import { useDispatchRoot, useSelectorRoot } from "../../../../../store/store";
import { setDetailFileSelected } from "../../../../../store/slice/projectSlice";

interface IContentMenu {
  fileId?: string | any;
  trigger?: ("click" | "hover" | "contextMenu")[];
}

export const ContentMenu: FC<LayoutProps & IContentMenu> = ({
  children,
  fileId,
  trigger = ["click"],
}) => {
  const dispatch = useDispatchRoot();
  const selectCurrentDisplayFiles = useSelectorRoot(
    (state) => state.project.currentDisplayFiles
  );
  const isChecked =
    selectCurrentDisplayFiles.filter(
      (file) => file.id === fileId && file.checked
    ).length > 0;
  const menuItems = [
    {
      label: "Permission",
      imgSrc: PermissionIcon,
    },
    {
      label: "Share",
      imgSrc: ShareIcon,
    },
    {
      label: "Comment",
      imgSrc: CommentIcon,
    },
    {
      label: "Add To Favourite",
      imgSrc: AddFavouriteIcon,
    },
    {
      label: "Details",
      imgSrc: DetailIcon,
      onClick: () =>
        fileId &&
        dispatch(
          setDetailFileSelected(
            selectCurrentDisplayFiles.filter((file) => file.id === fileId)[0]
          )
        ),
    },
    {
      label: "Rename",
      imgSrc: RenameIcon,
    },
    {
      label: "Move Or Copy",
      imgSrc: MoveCopyIcon,
    },
    {
      label: "Download",
      imgSrc: DownloadIcon,
    },
    {
      label: "Delete Folder",
      imgSrc: DeleteIcon,
    },
  ];
  const menuWhenItemSelected = [
    {
      label: "Share",
      imgSrc: ShareIcon,
    },
    {
      label: "Move Or Copy",
      imgSrc: MoveCopyIcon,
    },
    {
      label: "Download",
      imgSrc: DownloadIcon,
    },
    {
      label: "Delete Folder",
      imgSrc: DeleteIcon,
    },
  ];
  const getItems = (menu: any) => {
    return menu.map((item: any, index: number) => ({
      label: (
        <ContentMenuItem
          label={item.label}
          imgSrc={item.imgSrc}
          onClick={item.onClick && item.onClick}
        />
      ),
      key: index,
    }));
  };
  const items: MenuProps["items"] = getItems(
    isChecked ? menuWhenItemSelected : menuItems
  );
  return (
    <Dropdown
      dropdownRender={(menus) => {
        return (
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {menus}
          </div>
        );
      }}
      menu={{ items }}
      trigger={trigger}
    >
      {children}
    </Dropdown>
  );
};
