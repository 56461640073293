import React from "react";
import { IFile } from "../../../../../common/define-types";
import FolderIcon from "../../../../../image/icon/big-folder-icon.svg";
import styles from "./ProjectFile.module.css";
import { IconButton } from "../../../../../components/Buttons";
import { DashOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { useDispatchRoot, useSelectorRoot } from "../../../../../store/store";
import {
  setCurrentDisplayFiles,
  setFolderBreadcrum,
} from "../../../../../store/slice/projectSlice";
import { FileIcon } from "../../../../../components/File/FileIcon";
import { ContentMenu } from "./ContentMenu";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

interface IProps {
  file: IFile;
}
export const GridViewFileItem = ({ file }: IProps) => {
  const dispatch = useDispatchRoot();
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const selectDisplayFiles = useSelectorRoot(
    (state) => state.project.currentDisplayFiles
  );
  const selectFolderBreadcrum = useSelectorRoot(
    (state) => state.project.folderBreadcrum
  );
  const handleFileCheck = (value: any) => {
    const fileList = selectDisplayFiles.map((item) => {
      if (item.id === file.id) {
        return {
          ...item,
          checked: value.target.checked,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    dispatch(setCurrentDisplayFiles(fileList));
  };
  const handleFolderDblClick = () => {
    if (file.type === "folder") {
      const oldDir = values.dir ?? "";
      const newDir = `/${file.name}`;
      history.push({
        search: `?dir=${oldDir}${newDir}&fileId=${file.id}`,
      });
      dispatch(setFolderBreadcrum([...selectFolderBreadcrum, file.id]));
    }
  };

  return (
    <ContentMenu fileId={file.id} trigger={["contextMenu"]}>
      <div className={styles.gridViewFileItem}>
        <div
          className={styles.iconContainer}
          onDoubleClick={handleFolderDblClick}
        >
          {file.type === "folder" ? (
            <img src={FolderIcon} alt="folder" className={styles.fileIcon} />
          ) : (
            <FileIcon fileName={file.name} size={60} />
          )}
        </div>
        <div className={styles.fileInfo}>
          <span className={styles.fileName}>{file.name}</span>
          <ContentMenu fileId={file.id}>
            <IconButton
              size={24}
              icon={<DashOutlined style={{ fontSize: 12 }} />}
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </ContentMenu>
        </div>
        <Checkbox
          onChange={handleFileCheck}
          checked={file.checked}
          className={styles.fileCheckbox}
        />
      </div>
    </ContentMenu>
  );
};
