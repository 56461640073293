import { useEffect, useState } from "react";
import styles from "./Directory.module.css";
import { IFile } from "../../common/define-types";
import RightArrow from "../../image/icon/chevron-right.svg";
import DownArrow from "../../image/icon/chevron-down.svg";
import FolderIcon from "../../image/icon/folder-icon.svg";
import { Typography } from "antd";
import { FileIcon } from "../File/FileIcon";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatchRoot } from "store/store";
import { setFolderBreadcrum } from "store/slice/projectSlice";
interface IProps {
  file: IFile;
  path?: string;
  parentFoldersId?: string[];
}

const Directory = ({ file, path = "", parentFoldersId = [] }: IProps) => {
  const [isExpanded, toggleExpanded] = useState(false);
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatchRoot();
  const checkFolderActive = () => {
    const fileId = values.fileId;
    if (fileId) {
      setIsActive(file.id === fileId);
    } else {
      setIsActive(false);
    }
  };
  useEffect(() => {
    checkFolderActive();
  }, [search]);

  const handleFolderDblClick = (path: string, fileId: string) => {
    history.push({
      search: `?dir=${path}&fileId=${fileId}`,
    });
    dispatch(setFolderBreadcrum(parentFoldersId));
  };

  if (file.type === "folder") {
    const nextPath = `${path}/${file.name}`;
    const newParentFoldersId = [...parentFoldersId, file.id];
    return (
      <div className={`${styles.folder}`}>
        <div
          className={`${styles.folderItem} ${isActive && styles.folderActive}`}
          onDoubleClick={() => handleFolderDblClick(nextPath, file.id)}
        >
          <img
            src={isExpanded ? DownArrow : RightArrow}
            alt="arrow"
            onClick={() => toggleExpanded(!isExpanded)}
          />
          <img src={FolderIcon} alt="folder" />
          <Typography.Text className={styles.folderTitle}>
            {file.name}
          </Typography.Text>
        </div>
        {isExpanded &&
          file.items &&
          file.items.map((item) => (
            <Directory
              key={item.id}
              file={item}
              path={nextPath}
              parentFoldersId={newParentFoldersId}
            />
          ))}
      </div>
    );
  }
  return (
    <div className={`${styles.file}`}>
      <FileIcon fileName={file.name} />
      <Typography.Text className={`${styles.fileName}`}>
        {file.name}
      </Typography.Text>
    </div>
  );
};

export default Directory;
