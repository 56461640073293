export const dummyProjectFiles = [
  {
    prjId: "1",
    files: [
      {
        name: "folder1",
        type: "folder",
        size: "100MB",
        modified: "6 days ago",
        items: [
          {
            name: ".gitignore",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id1",
          },
          {
            name: "index.html",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id2",
          },
          {
            name: "src",
            type: "folder",
            size: "100MB",
            modified: "6 days ago",
            items: [
              {
                name: "App.css",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id4",
              },
              {
                name: "App.jsx",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id5",
              },
              {
                name: "index.dat",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id6",
              },
              {
                name: "main.eps",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id7",
              },
              {
                name: "components",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Checkbox.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id9",
                  },
                ],
                id: "id8",
              },
              {
                name: "pages",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Home.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id11",
                  },
                  {
                    name: "About.fla",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id12",
                  },
                ],
                id: "id10",
              },
            ],
            id: "id3",
          },
        ],
        id: "id0",
      },
      {
        name: "folder2",
        type: "folder",
        size: "100MB",
        modified: "6 days ago",
        items: [
          {
            name: ".gitignore",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id14",
          },
          {
            name: "index.html",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id15",
          },
          {
            name: "src",
            type: "folder",
            size: "100MB",
            modified: "6 days ago",
            items: [
              {
                name: "App.css",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id17",
              },
              {
                name: "App.jsx",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id18",
              },
              {
                name: "index.gif",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id19",
              },
              {
                name: "main.raw",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id20",
              },
              {
                name: "components",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Checkbox.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id22",
                  },
                ],
                id: "id21",
              },
              {
                name: "pages",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",

                id: "id23",
              },
            ],
            id: "id16",
          },
        ],
        id: "id13",
      },
      {
        name: "file1.3ds",
        type: "file",
        size: "100MB",
        modified: "6 days ago",
        id: "id26",
      },
    ],
  },
  {
    prjId: "2",
    files: [
      {
        name: "folder6",
        type: "folder",
        size: "100MB",
        modified: "6 days ago",
        items: [
          {
            name: ".gitignore",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id28",
          },
          {
            name: "index.html",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id29",
          },
          {
            name: "src",
            type: "folder",
            size: "100MB",
            modified: "6 days ago",
            items: [
              {
                name: "App.css",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id31",
              },
              {
                name: "App.jsx",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id32",
              },
              {
                name: "index.css",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id33",
              },
              {
                name: "main.jsx",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id34",
              },
              {
                name: "components",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Checkbox.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id36",
                  },
                ],
                id: "id35",
              },
              {
                name: "pages",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Home.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id38",
                  },
                  {
                    name: "About.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id39",
                  },
                ],
                id: "id37",
              },
            ],
            id: "id30",
          },
        ],
        id: "id27",
      },
      {
        name: "file5.cad",
        type: "file",
        size: "100MB",
        modified: "6 days ago",
        id: "id40",
      },
      {
        name: "folder5",
        type: "folder",
        size: "100MB",
        modified: "6 days ago",
        items: [
          {
            name: ".gitignore",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id42",
          },
          {
            name: "index.html",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id43",
          },
          {
            name: "src",
            type: "folder",
            size: "100MB",
            modified: "6 days ago",
            items: [
              {
                name: "App.wmv",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id45",
              },
              {
                name: "App.svg",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id46",
              },
              {
                name: "index.png",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id47",
              },
              {
                name: "main.jsx",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id48",
              },
              {
                name: "components",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Checkbox.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id50",
                  },
                ],
                id: "id49",
              },
              {
                name: "pages",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Home.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id52",
                  },
                  {
                    name: "About.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id53",
                  },
                ],
                id: "id51",
              },
            ],
            id: "id44",
          },
        ],
        id: "id41",
      },
    ],
  },
  {
    prjId: "3",
    files: [
      {
        name: "folder3",
        type: "folder",
        size: "100MB",
        modified: "6 days ago",
        items: [
          {
            name: ".gitignore",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id55",
          },
          {
            name: "index.html",
            type: "file",
            size: "100MB",
            modified: "6 days ago",
            id: "id56",
          },
          {
            name: "src",
            type: "folder",
            size: "100MB",
            modified: "6 days ago",
            items: [
              {
                name: "App.css",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id58",
              },
              {
                name: "App.jsx",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id59",
              },
              {
                name: "index.css",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id60",
              },
              {
                name: "main.jsx",
                type: "file",
                size: "100MB",
                modified: "6 days ago",
                id: "id61",
              },
              {
                name: "components",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Checkbox.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id63",
                  },
                ],
                id: "id62",
              },
              {
                name: "pages",
                type: "folder",
                size: "100MB",
                modified: "6 days ago",
                items: [
                  {
                    name: "Home.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id65",
                  },
                  {
                    name: "About.jsx",
                    type: "file",
                    size: "100MB",
                    modified: "6 days ago",
                    id: "id66",
                  },
                ],
                id: "id64",
              },
            ],
            id: "id57",
          },
        ],
        id: "id54",
      },
      {
        name: "file4.pdf",
        type: "file",
        size: "100MB",
        modified: "6 days ago",
        id: "id67",
      },
    ],
  },
];
