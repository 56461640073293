import React, { useState, useEffect } from "react";
import { ListViewFiles } from "./ListViewFiles";
import { useDispatchRoot, useSelectorRoot } from "../../../../../store/store";
import { IFile } from "../../../../../common/define-types";
import { GridViewFiles } from "./GridViewFiles";
import ListViewIcon from "../../../../../image/icon/view-list-icon.svg";
import GridViewIcon from "../../../../../image/icon/view-grid-icon.svg";
import TreeViewIcon from "../../../../../image/icon/view-tree-icon.svg";
import { Segmented } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { FILE_VIEW } from "../../../../../common/constants";
import styles from "./ProjectFile.module.css";
import { TreeViewFiles } from "./TreeViewFiles";
import {
  setCurrentDisplayFiles,
  setDetailFileSelected,
  setFolderBreadcrum,
} from "../../../../../store/slice/projectSlice";
import { FileDetail } from "./fileDetail/FileDetail";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const ProjectFiles = () => {
  const selectProjectFiles =
    useSelectorRoot((state) => state.project.projectFile) ?? [];
  const selectCurrentDisplayFiles =
    useSelectorRoot((state) => state.project.currentDisplayFiles) ?? [];
  const selectFileDetail = useSelectorRoot(
    (state) => state.project.detailFileSelected
  );

  const dispatch = useDispatchRoot();
  const [activeView, setActiveView] = useState<string | number>(
    FILE_VIEW.LIST_VIEW
  );

  const { search } = useLocation();
  const values = queryString.parse(search);
  const fileId: string | (string | null)[] = values.fileId ?? "";

  useEffect(() => {
    dispatch(setCurrentDisplayFiles(selectProjectFiles));
  }, [selectProjectFiles]);

  useEffect(() => {
    const getFileItems = (
      file: IFile,
      fileId: string | (string | null)[]
    ): IFile[] | null | undefined => {
      if (file.id === fileId) {
        return file.items ?? [];
      } else if (file.items != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < file.items.length; i++) {
          result = getFileItems(file.items[i], fileId);
        }
        return result;
      }
      return null;
    };

    if (fileId && fileId !== "") {
      for (var i = 0; i < selectProjectFiles.length; i++) {
        const result = getFileItems(selectProjectFiles[i], fileId);
        if (result) {
          dispatch(
            setCurrentDisplayFiles(
              result.map((file) => ({
                ...file,
                checked: false,
              }))
            )
          );
          return;
        }
      }
    } else {
      dispatch(setCurrentDisplayFiles(selectProjectFiles));
      dispatch(setFolderBreadcrum([]));
    }
  }, [fileId]);

  const handleViewChange = (value: SegmentedValue) => {
    setActiveView(value);
  };
  const renderView = () => {
    switch (activeView) {
      case FILE_VIEW.GRID_VIEW:
        return <GridViewFiles files={selectCurrentDisplayFiles} />;
      case FILE_VIEW.TREE_VIEW:
        return <TreeViewFiles files={selectCurrentDisplayFiles} />;
      default:
        return <ListViewFiles files={selectCurrentDisplayFiles} />;
    }
  };

  return (
    <>
      <div className={styles.viewContainer}>
        <Segmented
          value={activeView}
          onChange={handleViewChange}
          className="selectView"
          options={[
            {
              label: (
                <div className={styles.viewItemContainer}>
                  <img
                    src={ListViewIcon}
                    className={styles.viewImg}
                    alt="list"
                  />
                </div>
              ),
              value: FILE_VIEW.LIST_VIEW,
            },
            {
              label: (
                <div className={styles.viewItemContainer}>
                  <img
                    src={GridViewIcon}
                    className={styles.viewImg}
                    alt="grid"
                  />
                </div>
              ),
              value: FILE_VIEW.GRID_VIEW,
            },
            {
              label: (
                <div className={styles.viewItemContainer}>
                  <img
                    src={TreeViewIcon}
                    className={styles.viewImg}
                    alt="tree"
                  />
                </div>
              ),
              value: FILE_VIEW.TREE_VIEW,
            },
          ]}
        />
      </div>
      <FileDetail
        detailFile={selectFileDetail}
        onClose={() => dispatch(setDetailFileSelected(null))}
      />
      {renderView()}
    </>
  );
};
