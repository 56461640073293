import React, { useEffect } from "react";
import { Layout } from "antd";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { ProjectRoutes, RootClaim } from "../MenuRoutes/Routes";
import { PrivateRoute } from "../../components/Routes";
import { WaitOverlay } from "../../components/overlay/WaitOverlay";
import { LeftMenu } from "../leftMenu/LeftMenu";
import { useDispatchRoot, useSelectorRoot } from "../../store/store";
import { dummyProjectFiles } from "dummyData/projectFiles";
import { IFile } from "common/define-types";
import { setProjectFiles } from "store/slice/projectSlice";

const { Content } = Layout;
export const ProjectLayout = () => {
  const selectProject = useSelectorRoot(
    (state) => state.project.projectSelected
  );
  const dispatch = useDispatchRoot();
  const history = useHistory();
  useEffect(() => {
    if (!selectProject) {
      history.push("/projects");
    } else {
      const projectFiles: any = dummyProjectFiles.filter(
        (item) => item.prjId === selectProject?.id
      );
      if (projectFiles.length > 0) {
        if (projectFiles[0].files) {
          dispatch(
            setProjectFiles(
              projectFiles[0].files.map((file: IFile) => {
                return {
                  ...file,
                  checked: false,
                };
              })
            )
          );
          return;
        }
      } else {
        dispatch(setProjectFiles([]));
      }
    }
  }, [selectProject, history]);

  const renderMain = () => {
    const routerProps = [...ProjectRoutes];
    return routerProps.map(
      ({ label, rootPermission, routeProps, isLabelDisplay }) => (
        <PrivateRoute
          allowedRoles={[...(rootPermission ?? [RootClaim.All])]}
          key={`${label}`}
          label={isLabelDisplay === false ? "" : label}
          {...routeProps}
        />
      )
    );
  };
  return (
    <WaitOverlay>
      <Layout style={{ height: "100%" }}>
        <LeftMenu routes={ProjectRoutes} />
        <Content style={{ backgroundColor: "#f3f3f7", paddingTop: 10 }}>
          <Switch>
            {renderMain()}
            <Redirect to="/project/dashboard" />
          </Switch>
        </Content>
      </Layout>
    </WaitOverlay>
  );
};
