import React from "react";
import { IFile } from "../../../../../common/define-types";
import { Space, Empty } from "antd";
import styles from "./ProjectFile.module.css";
import Directory from "../../../../../components/Folder/Directory";
import { useSelectorRoot } from "../../../../../store/store";
import { GridViewFileItem } from "./GridViewFileItem";
interface IProps {
  files: IFile[];
}
export const TreeViewFiles = ({ files }: IProps) => {
  const selectCurrentDisplayFiles =
    files.filter((file) => file.type === "file") ?? [];
  const selectProjectFiles =
    useSelectorRoot((state) => state.project.projectFile) ?? [];
  return (
    <div className={styles.treeViewFiles}>
      <div className={`${styles.treeView} treeView customScroll`}>
        {selectProjectFiles && selectProjectFiles.length > 0 ? (
          selectProjectFiles.map((file) => (
            <Directory key={file.id} file={file} />
          ))
        ) : (
          <Space style={{ width: "100%", justifyContent: "center" }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"Empty folder"}
            />
          </Space>
        )}
      </div>
      <div className={styles.gridViewFiles}>
        {selectCurrentDisplayFiles && selectCurrentDisplayFiles.length > 0 ? (
          selectCurrentDisplayFiles.map((file, index) => (
            <GridViewFileItem key={index} file={file} />
          ))
        ) : (
          <Space style={{ width: "100%", justifyContent: "center" }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Space>
        )}
      </div>
    </div>
  );
};
